/* latin-ext */
@font-face {
  font-family: '__Bebas_Neue_c65363';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8be698a80cb51f2b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bebas_Neue_c65363';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/86667d917ceb322f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Bebas_Neue_Fallback_c65363';src: local("Arial");ascent-override: 116.50%;descent-override: 38.83%;line-gap-override: 0.00%;size-adjust: 77.25%
}.__className_c65363 {font-family: '__Bebas_Neue_c65363', '__Bebas_Neue_Fallback_c65363';font-weight: 400;font-style: normal
}.__variable_c65363 {--font-bebas-neue: '__Bebas_Neue_c65363', '__Bebas_Neue_Fallback_c65363'
}

